html {
    box-sizing: border-box;
    --duration: .7s;
    --menuHeight: 5rem;
    --slideShowMaxHeight: 12rem;
    --slideShowMinHeight: 12rem;
    --slideShowHeight: 12rem;
}
/*MENU*/

.menu{
    margin: 0;
    display: flex;
    /* Works well with 100% width  */
    /*width: 32.05em;*/
    font-size: 1.5em;
    padding: 0 1em;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    min-height: 6rem;
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    /*width: 93%;*/
    z-index: 49;
    font-family: 'Quicksand'!important;
    padding-left: 20px;
    padding-right: 20px;
}

.menu .menu__item{
    all: unset;
    flex-grow: 1;
    z-index: 100;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    align-items: center;
    will-change: transform;
    justify-content: center;
    padding: 0.55em 0 0.85em;
    transition: transform var(--duration);
    font-family: 'Quicksand'!important;
}

.menu .menu__item a::before{
    content: "";
    font-family: 'Quicksand'!important;
    z-index: -1;
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
    position: absolute;
    transform: scale(0);
    transition: background-color var(--duration), transform var(--duration);
}

.menu .menu__item > .active {
    transform: translate3d(0, -1.2em , 0);
}

.menu .menu__item > a {
    transform: translate3d(0, 0em , 0);
}

.menu .menu__item > .active::before{
    transform: scale(1);
    /*background-color: white;*/
}

.menu .icon{
    width: 2em;
    height: 2em;
}

.menu .menu__item > .active .icon svg {
    animation: strok 1.5s reverse;
}

.menu .menu__item .icon-regular svg ,
.menu .menu__item > .active .icon-active svg {
    opacity: 0;
}
.menu .menu__item .icon-active svg ,
.menu .menu__item > .active .icon-regular svg {
    opacity: 1;
}

.menu .menu__item > .active p {
    opacity: 1;
    position: absolute;
    bottom: -40px;
    transition: opacity .3s .3s;
}

.menu .menu__item p {
    opacity: 0;
}

.menu .menu__item,
.menu .menu__item a {
    position: relative;
    width: 40px;
}

.menu .menu__item a svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes strok {
    100% {
        stroke-dashoffset: 400;
    }
}

.menu .menu__border{
    left: 0;
    bottom: 99%;
    width: 10.9em;
    height: 3.0em;
    position: absolute;
    clip-path: url(#menu);
    will-change: transform;
    transition: transform var(--duration);
}

.svg-container {
    width: 0;
    height: 0;
}
.menu .icon-regular svg,
.menu .icon-active svg{
    width: 1.5rem;
    height: 1.5rem;
}

@media screen and (max-width: 50em) {
    .menu{
        font-size: .8em;
        min-height: 5rem;
        max-height: 5rem;
    }
    .menu .icon-regular svg,
    .menu .icon-active svg{
        width: 1.3rem;
        height: 1.3rem;
    }
    .menu .menu__item > .active p {
        bottom: -30px;
        width: 80px;
        text-align: center;
    }
    .menu .menu__item a::before{
        content: "";
        z-index: -1;
        width: 3.2em;
        height: 3.2em;
        border-radius: 50%;
        position: absolute;
        transform: scale(0);
        transition: background-color var(--duration), transform var(--duration);
    }
}
/*FINE MENU*/
