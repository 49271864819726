.expense-date {
    display: flex;
    flex-direction: column;
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #ececec;
    background-color: #2a2a2a;
    color: white;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
  }

  .expense-date__month {
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: capitalize;
  }

  .expense-date__year {
    font-size: 0.75rem;
  }

  .expense-date__day {
    font-size: 1.5rem;
    font-weight: bold;
  }

.test {
    color: #F8FAFC;
    color: #F1F5F9;
    color: #E2E8F0;
    color: #CBD5E1;
    color: #94A3B8;
    color: #64748B;
    color: #475569;
    color: #334155;
    color: #1E293B;
    color: #0F172A;
    color: #F9FAFB;
    color: #F3F4F6;
    color: #E5E7EB;
    color: #D1D5DB;
    color: #9CA3AF;
    color: #6B7280;
    color: #4B5563;
    color: #374151;
    color: #1F2937;
    color: #111827;
    color: #FAFAFA;
    color: #F4F4F5;
    color: #E4E4E7;
    color: #D4D4D8;
    color: #A1A1AA;
    color: #71717A;
    color: #52525B;
    color: #3F3F46;
    color: #27272A;
    color: #18181B;
    color: #FAFAFA;
    color: #F5F5F5;
    color: #E5E5E5;
    color: #D4D4D4;
    color: #A3A3A3;
    color: #737373;
    color: #525252;
    color: #404040;
    color: #262626;
    color: #171717;
    color: #FAFAF9;
    color: #F5F5F4;
    color: #E7E5E4;
    color: #D6D3D1;
    color: #A8A29E;
    color: #78716C;
    color: #57534E;
    color: #44403C;
    color: #292524;
    color: #1C1917;
    color: #FEF2F2;
    color: #FEE2E2;
    color: #FECACA;
    color: #FCA5A5;
    color: #F87171;
    color: #EF4444;
    color: #DC2626;
    color: #B91C1C;
    color: #991B1B;
    color: #7F1D1D;
    color: #FFF7ED;
    color: #FFEDD5;
    color: #FED7AA;
    color: #FDBA74;
    color: #FB923C;
    color: #F97316;
    color: #EA580C;
    color: #C2410C;
    color: #9A3412;
    color: #7C2D12;
    color: #FFFBEB;
    color: #FEF3C7;
    color: #FDE68A;
    color: #FCD34D;
    color: #FBBF24;
    color: #F59E0B;
    color: #D97706;
    color: #B45309;
    color: #92400E;
    color: #78350F;
    color: #FEFCE8;
    color: #FEF9C3;
    color: #FEF08A;
    color: #FDE047;
    color: #FACC15;
    color: #EAB308;
    color: #CA8A04;
    color: #A16207;
    color: #854D0E;
    color: #713F12;
    color: #F7FEE7;
    color: #ECFCCB;
    color: #D9F99D;
    color: #BEF264;
    color: #A3E635;
    color: #84CC16;
    color: #65A30D;
    color: #4D7C0F;
    color: #3F6212;
    color: #365314;
    color: #F0FDF4;
    color: #DCFCE7;
    color: #BBF7D0;
    color: #86EFAC;
    color: #4ADE80;
    color: #22C55E;
    color: #16A34A;
    color: #15803D;
    color: #166534;
    color: #14532D;
    color: #ECFDF5;
    color: #D1FAE5;
    color: #A7F3D0;
    color: #6EE7B7;
    color: #34D399;
    color: #10B981;
    color: #059669;
    color: #047857;
    color: #065F46;
    color: #064E3B;
    color: #F0FDFA;
    color: #CCFBF1;
    color: #99F6E4;
    color: #5EEAD4;
    color: #2DD4BF;
    color: #14B8A6;
    color: #0D9488;
    color: #0F766E;
    color: #115E59;
    color: #134E4A;
    color: #ECFEFF;
    color: #CFFAFE;
    color: #A5F3FC;
    color: #67E8F9;
    color: #22D3EE;
    color: #06B6D4;
    color: #0891B2;
    color: #0E7490;
    color: #155E75;
    color: #164E63;
    color: #F0F9FF;
    color: #E0F2FE;
    color: #BAE6FD;
    color: #7DD3FC;
    color: #38BDF8;
    color: #0EA5E9;
    color: #0284C7;
    color: #0369A1;
    color: #075985;
    color: #0C4A6E;
    color: #EFF6FF;
    color: #DBEAFE;
    color: #BFDBFE;
    color: #93C5FD;
    color: #60A5FA;
    color: #3B82F6;
    color: #2563EB;
    color: #1D4ED8;
    color: #1E40AF;
    color: #1E3A8A;
    color: #EEF2FF;
    color: #E0E7FF;
    color: #C7D2FE;
    color: #A5B4FC;
    color: #818CF8;
    color: #6366F1;
    color: #4F46E5;
    color: #4338CA;
    color: #3730A3;
    color: #312E81;
    color: #F5F3FF;
    color: #EDE9FE;
    color: #DDD6FE;
    color: #C4B5FD;
    color: #A78BFA;
    color: #8B5CF6;
    color: #7C3AED;
    color: #6D28D9;
    color: #5B21B6;
    color: #4C1D95;
    color: #FAF5FF;
    color: #F3E8FF;
    color: #E9D5FF;
    color: #D8B4FE;
    color: #C084FC;
    color: #A855F7;
    color: #9333EA;
    color: #7E22CE;
    color: #6B21A8;
    color: #581C87;
    color: #FDF4FF;
    color: #FAE8FF;
    color: #F5D0FE;
    color: #F0ABFC;
    color: #E879F9;
    color: #D946EF;
    color: #C026D3;
    color: #A21CAF;
    color: #86198F;
    color: #701A75;
    color: #FDF2F8;
    color: #FCE7F3;
    color: #FBCFE8;
    color: #F9A8D4;
    color: #F472B6;
    color: #EC4899;
    color: #DB2777;
    color: #BE185D;
    color: #9D174D;
    color: #831843;
    color: #FFF1F2;
    color: #FFE4E6;
    color: #FECDD3;
    color: #FDA4AF;
    color: #FB7185;
    color: #F43F5E;
    color: #E11D48;
    color: #BE123C;
    color: #9F1239;
    color: #881337
}
