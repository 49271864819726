.h-screen-servizi{
    height: calc(100vh - 350px);
}

.leaflet-popup-content{
    margin: 0px;
    min-width: 300px;
}
.leaflet-popup-content-wrapper{
    padding: 0px;
}
.leaflet-popup-content h2{
    font-weight: normal!important;
    font-size: 26px;
}


.leaflet-popup-content a{
    font-weight: 500!important;
}
.leaflet-container a.leaflet-popup-close-button{
    top: 20px;
    right: 10px;
}


.leaflet-container a{
    color: rgb(55 65 81);
}