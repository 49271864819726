@media (max-width: 1024px) {

    .scopri-filter .active .icon-active {
        display: block;
    }

    .scopri-filter .active .icon-regular,
    .scopri-filter .icon-active {
        display: none;
    }
}


@media (min-width: 1024px) {
    .scopri-filter .icon-regular,
    .scopri-filter .active .icon-active {
        display: none;
    }

    .scopri-filter .active .icon-regular,
    .scopri-filter .icon-active {
        display: block;
    }

    .scopri-filter .active .text{
        color: black;
    }
    .scopri-filter .active{
        color: black;
        border-radius: 50px;
        margin: 0 auto
    }
    .menu__item{
        margin: 0 auto!important
    }

}

.scopri-filter .icon svg{
    height: 40px;
    width: 40px;
}

