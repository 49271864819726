@font-face {
    font-family: 'Quicksand';
    src: url('fonts/Quicksand-Regular.eot');
    src: local('Quicksand Regular'), local('Quicksand-Regular'),
    url('fonts/Quicksand-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Quicksand-Regular.woff2') format('woff2'),
    url('fonts/Quicksand-Regular.woff') format('woff'),
    url('fonts/Quicksand-Regular.ttf') format('truetype'),
    url('fonts/Quicksand-Regular.svg#Quicksand-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src: url('fonts/Quicksand-Medium.eot');
    src: local('Quicksand Medium'), local('Quicksand-Medium'),
    url('fonts/Quicksand-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/Quicksand-Medium.woff2') format('woff2'),
    url('fonts/Quicksand-Medium.woff') format('woff'),
    url('fonts/Quicksand-Medium.ttf') format('truetype'),
    url('fonts/Quicksand-Medium.svg#Quicksand-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

body{
    font-family: 'Quicksand'!important;
}
p{
    font-size: 16px;
}

/*.pswp__zoom-wrap {*/
/*    position: relative !important;*/
/*    height: 100vh !important;*/
/*    left: 0!important;*/
    /*top: 0px;*/
    /*transform: none !important;*/
/*}*/
/*.pswp__img{*/
    /*width: 300px!important;*/
    /*height: auto!important;*/
    /*object-fit: cover;*/
    /*position: absolute;*/
    /*top: 50%!important;*/
    /*left: 50%!important;*/
    /*transform: translate(-50%, -50%)!important;*/
/*}*/
@media (min-width: 1024px){
    .centrato{
        bottom: 260px
        /*top:50%;*/
        /*transform: translateY(-50%);*/
    }
    .verdescuro{
        background: #21442B!important;
    }

    p{
        font-size: 18px;
    }
}


